export const DATASET_DOWNLOAD_REQUEST_SEND = 'DATASET_DOWNLOAD_REQUEST_SEND';
export const DATASET_DOWNLOAD_REQUEST_SUCCESS =
  'DATASET_DOWNLOAD_REQUEST_SUCCESS';
export const DATASET_DOWNLOAD_REQUEST_FAILURE =
  'DATASET_DOWNLOAD_REQUEST_FAILURE';
export const DATASET_DOWNLOAD_REQUEST_PROCESSING =
  'DATASET_DOWNLOAD_REQUEST_PROCESSING';

export const CHECK_QUERY_SIZE_SEND = 'CHECK_QUERY_SIZE_SEND';
export const SET_CHECK_QUERY_SIZE_REQUEST_STATE = 'SET_CHECK_QUERY_SIZE_STATE';
export const STORE_CHECK_QUERY_SIZE_RESULT = 'STORE_CHECK_QUERY_SIZE_RESULT';
export const CLEAR_FAILED_SIZE_CHECKS = 'CLEAR_FAILED_SIZE_CHECKS';

export const SUBMISSION_OPTIONS_RETRIEVAL = 'SUBMISSION_OPTIONS_RETRIEVAL';
export const STORE_SUBMISSION_OPTIONS = 'STORE_SUBMISSION_OPTIONS';

export const KEYWORDS_FETCH = 'KEYWORDS_FETCH';
export const KEYWORDS_STORE = 'KEYWORDS_STORE';

export const SEARCH_OPTIONS_FETCH = 'SEARCH_OPTIONS_FETCH';
export const SEARCH_OPTIONS_STORE = 'SEARCH_OPTIONS_STORE';

export const SEARCH_RESULTS_FETCH = 'SEARCH_RESULTS_FETCH';
export const SEARCH_RESULTS_STORE = 'SEARCH_RESULTS_STORE';
export const SEARCH_RESULTS_SET_LOADING_STATE =
  'SEARCH_RESULTS_SET_LOADING_STATE';

export const DATASET_FULL_PAGE_NAVIGATE = 'DATASET_FULL_PAGE_NAVIGATE';
export const DATASET_FULL_PAGE_DATA_FETCH = 'DATASET_FULL_PAGE_DATA_FETCH';
export const DATASET_FULL_PAGE_DATA_STORE = 'DATASET_FULL_PAGE_DATA_STORE';
export const DATASET_FULL_PAGE_DATA_SET_LOADING_STATE =
  'DATASET_FULL_PAGE_DATA_SET_LOADING_STATE';

export const DATASET_VISUALIZABLE_VARS_FETCH = 'DATASET_VIS_VARS_FETCH';
export const DATASET_VISUALIZABLE_VARS_STORE = 'DATASET_VIS_VARS_STORE';
export const DATASET_VISUALIZABLE_VARS_SET_LOADING_STATE = 'DATASET_VIS_VARS_SET_STATE';

export const DATASET_VARIABLE_UM_FETCH = 'DATASET_VARIABLE_UM_FETCH';
export const DATASET_VARIABLE_UM_STORE = 'DATASET_VARIABLE_UM_STORE';
export const DATASET_VARIABLE_UM_SET_LOADING_STATE = 'DATASET_VARIABLE_UM_SET_LOADING_STATE';

export const DATASET_VARIABLES_FETCH = 'DATASET_VARIABLES_FETCH';
export const DATASET_VARIABLES_STORE = 'DATASET_VARIABLES_STORE';
export const DATASET_VARIABLES_SET_LOADING_STATE = 'DATASET_VARIABLES_SET_LOADING_STATE';

export const DATASET_VARIABLE_VIS_DATA_FETCH = 'DATASET_VARIABLE_VIS_DATA_FETCH';
export const DATASET_VARIABLE_VIS_DATA_STORE= 'DATASET_VARIABLE_VIS_DATA_STORE';
export const DATASET_VARIABLE_VIS_DATA_SET_LOADING_STATE = 'DATASET_VARIABLE_VIS_DATA_SET_LOADING_STATE';

export const DATASET_VARIABLE_SELECT = 'DATASET_VARIABLE_SELECT';
export const DATASET_VIS_VAR_TAB_PREFERENCE = 'DATASET_VIS_VAR_TAB_PREFERENCE';

export const CRUISE_FULL_PAGE_DATA_FETCH = 'CRUISE_FULL_PAGE_DATA_FETCH';
export const CRUISE_FULL_PAGE_DATA_STORE = 'CRUISE_FULL_PAGE_DATA_STORE';
export const CRUISE_FULL_PAGE_DATA_SET_LOADING_STATE =
  'CRUISE_FULL_PAGE_DATA_SET_LOADING_STATE';

export const CATALOG_TOUR_END = 'CATALOG_TOUR_END';

export const FETCH_ANCILLARY_DATA_FOR_DATASET_SEND =
  'FETCH_ANCILLARY_DATA_FOR_DATASET_SEND';
export const FETCH_ANCILLARY_DATA_FOR_DATASET_SUCCESS =
  'FETCH_ANCILLARY_DATA_FOR_DATASET_SUCCESS';
export const FETCH_ANCILLARY_DATA_FOR_DATASET_FAILURE =
  'FETCH_ANCILLARY_DATA_FOR_DATASET_FAILURE';

export const FETCH_DATASET_FEATURES = 'FETCH_DATASET_FEATURES';
export const FETCH_DATASET_FEATURES_SUCCESS = 'FETCH_DATASET_FEATURES_SUCCESS';
export const FETCH_DATASET_FEATURES_FAILURE = 'FETCH_DATASET_FEATURES_FAILURE';

export const UPDATE_CATALOG_WITH_DATASET_FEATURES = 'UPDATE_CATALOG_WITH_DATASET_FEATURES';

export const FETCH_RECS_POPULAR_SEND = 'FETCH_RECS_POPULAR_SEND';
export const FETCH_RECS_POPULAR_SUCCESS = 'FETCH_RECS_POPULAR_SUCCESS';
export const FETCH_RECS_POPULAR_FAILURE = 'FETCH_RECS_POPULAR_FAILURE';

export const FETCH_RECS_RECENT_SEND = 'FETCH_RECS_RECENT_SEND';
export const FETCH_RECS_RECENT_SUCCESS = 'FETCH_RECS_RECENT_SUCCESS';
export const FETCH_RECS_RECENT_FAILURE = 'FETCH_RECS_RECENT_FAILURE';
export const FETCH_RECS_RECENT_CACHE_HIT = 'FETCH_RECS_RECENT_CACHE_HIT';

export const FETCH_RECS_RECOMMENDED_SEND = 'FETCH_RECS_RECOMMENDED_SEND';
export const FETCH_RECS_RECOMMENDED_SUCCESS = 'FETCH_RECS_RECOMMENDED_SUCCESS';
export const FETCH_RECS_RECOMMENDED_FAILURE = 'FETCH_RECS_RECOMMENDED_FAILURE';
export const FETCH_RECS_RECOMMENDED_CACHE_HIT = 'FETCH_RECS_RECOMMENDED_CACHE_HIT';

export const SET_SORTING_OPTIONS = 'SET_SORTING_OPTIONS';

export const FETCH_PROGRAMS_SEND = 'FETCH_PROGRAMS_SEND';
export const FETCH_PROGRAMS_SUCCESS = 'FETCH_PROGRAMS_SUCCESS';
export const FETCH_PROGRAMS_FAILURE = 'FETCH_PROGRAMS_FAILURE';

export const FETCH_PROGRAM_DETAILS_SEND = 'FETCH_PROGRAM_DETAILS_SEND';
export const FETCH_PROGRAM_DETAILS_SUCCESS = 'FETCH_PROGRAM_DETAILS_SUCCESS';
export const FETCH_PROGRAM_DETAILS_FAILURE = 'FETCH_PROGRAM_DETAILS_FAILURE';

export const SET_PROGRAM_CRUISE_TRAJECTORY_FOCUS = 'SET_PROGRAM_CRUISE_TRAJECTORY_FOCUS';
export const PROGRAM_DATASET_SELECT = 'PROGRAM_DATASET_SELECT';
export const PROGRAM_DATASET_VARIABLE_SELECT = 'PROGRAM_DATASET_VARIABLE_SELECT';

export const PROGRAM_SAMPLE_VIS_DATA_FETCH= 'PROGRAM_SAMPLE_VIS_DATA_FETCH';
export const PROGRAM_SAMPLE_VIS_DATA_SET_LOADING_STATE = 'PROGRAM_SAMPLE_VIS_DATA_SET_LOADING_STATE';
export const PROGRAM_SAMPLE_VIS_DATA_STORE = 'PROGRAM_SAMPLE_VIS_DATA_STORE';

// export const PROGRAM_DATASET_VIS_VAR_TAB_PREFERENCE = 'DATASET_VIS_VAR_TAB_PREFERENCE';

export const FETCH_DATASET_NAMES = 'FETCH_DATASET_NAMES';
export const FETCH_DATASET_NAMES_SUCCESS = 'FETCH_DATASET_NAMES_SUCCESS';
export const SET_DATASET_NAMES_REQUEST_STATUS = 'SET_DATASET_NAMES_REQUEST_STATUS';
