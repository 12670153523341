export const FETCH_NOTIFICATION_HISTORY = 'FETCH_NOTIFICATION_HISTORY';
export const FETCH_NOTIFICATION_HISTORY_SUCCESS = 'FETCH_NOTIFICATION_HISTORY_SUCCESS';
export const SET_NOTIFICATION_HISTORY_REQUEST_STATUS = 'SET_NOTIFICATION_HISTORY_REQUEST_STATUS';

export const FETCH_NOTIFICATION_RECIPIENT_PROJECTION = 'FETCH_NOTIFICATION_RECIPIENT_PROJECTION';
export const FETCH_NOTIFICATION_RECIPIENT_PROJECTION_SUCCESS = 'FETCH_NOTIFICATION_RECIPIENT_PROJECTION_SUCCESS';
export const SET_NOTIFICATION_RECIPIENT_PROJECTION_REQUEST_STATUS = 'SET_NOTIFICATION_RECIPIENT_PROJECTION_REQUEST_STATUS';

export const FETCH_NOTIFICATION_PREVIEWS = 'FETCH_NOTIFICATION_PREVIEWS';
export const FETCH_NOTIFICATION_PREVIEWS_SUCCESS = 'FETCH_NOTIFICATION_PREVIEWS_SUCCESS';
export const SET_FETCH_NOTIFICATION_PREVIEWS_REQUEST_STATUS = 'SET_FETCH_NOTIFICATION_PREVIEWS_REQUEST_STATUS';

export const SEND_NOTIFICATIONS = 'SEND_NOTIFICATIONS';
export const SEND_NOTIFICATIONS_RESULT = 'SEND_NOTIFICATIONS_RESULT';
export const SET_SEND_NOTIFICATIONS_STATUS = 'SET_SEND_NOTIFICATIONS_STATUS';

export const RESEND_NOTIFICATIONS = 'RESEND_NOTIFICATIONS';
export const RESEND_NOTIFICATIONS_RESULT = 'RESEND_NOTIFICATIONS_RESULT';
export const SET_RESEND_NOTIFICATIONS_STATUS = 'SET_RESEND_NOTIFICATIONS_STATUS';
