export const REQUEST_NEWS_LIST_SEND = 'REQUEST_NEWS_LIST_SEND';
export const REQUEST_NEWS_LIST_SUCCESS = 'REQUEST_NEWS_LIST_SUCCESS';
export const REQUEST_NEWS_LIST_FAILURE = 'REQUEST_NEWS_LIST_FAILURE';

export const CREATE_NEWS_ITEM_SEND = 'CREATE_NEWS_ITEM_SEND';
export const CREATE_NEWS_ITEM_SUCCESS = 'CREATE_NEWS_ITEM_SUCCESS';
export const CREATE_NEWS_ITEM_FAILURE = 'CREATE_NEWS_ITEM_FAILURE';

export const UPDATE_NEWS_ITEM_SEND = 'UDPATE_NEWS_ITEM_SEND';
export const UPDATE_NEWS_ITEM_SUCCESS = 'UDPATE_NEWS_ITEM_SUCCESS';
export const UPDATE_NEWS_ITEM_FAILURE = 'UDPATE_NEWS_ITEM_FAILURE';

export const UPDATE_NEWS_RANKS_SEND = 'UPDATE_NEWS_RANKS_SEND';
export const UPDATE_NEWS_RANKS_SUCCESS = 'UPDATE_NEWS_RANKS_SUCCESS';
export const UPDATE_NEWS_RANKS_FAILURE = 'UPDATE_NEWS_RANKS_FAILURE';

export const PUBLISH_NEWS_ITEM_SEND = 'PUBLISH_NEWS_ITEM_SEND';
export const PUBLISH_NEWS_ITEM_SUCCESS = 'PUBLISH_NEWS_ITEM_SUCCESS';
export const PUBLISH_NEWS_ITEM_FAILURE = 'PUBLISH_NEWS_ITEM_FAILURE';

export const PREVIEW_NEWS_ITEM_SEND = 'PREVIEW_NEWS_ITEM_SEND';
export const PREVIEW_NEWS_ITEM_SUCCESS = 'PREVIEW_NEWS_ITEM_SUCCESS';
export const PREVIEW_NEWS_ITEM_FAILURE = 'PREVIEW_NEWS_ITEM_FAILURE';

export const DRAFT_NEWS_ITEM_SEND = 'DRAFT_NEWS_ITEM_SEND';
export const DRAFT_NEWS_ITEM_SUCCESS = 'DRAFT_NEWS_ITEM_SUCCESS';
export const DRAFT_NEWS_ITEM_FAILURE = 'DRAFT_NEWS_ITEM_FAILURE';

export const UNPUBLISH_NEWS_ITEM_SEND = 'UNPUBLISH_NEWS_ITEM_SEND';
export const UNPUBLISH_NEWS_ITEM_SUCCESS = 'UNPUBLISH_NEWS_ITEM_SUCCESS';
export const UNPUBLISH_NEWS_ITEM_FAILURE = 'UNPUBLISH_NEWS_ITEM_FAILURE';

export const FEATURE_NEWS_ITEM_SEND = 'FEATURE_NEWS_ITEM_SEND';
export const FEATURE_NEWS_ITEM_SUCCESS = 'FEATURE_NEWS_ITEM_SUCCESS';
export const FEATURE_NEWS_ITEM_FAILURE = 'FEATURE_NEWS_ITEM_FAILURE';

export const CATEGORIZE_NEWS_ITEM_SEND = 'CATEGORIZE_NEWS_ITEM_SEND';
export const CATEGORIZE_NEWS_ITEM_SUCCESS = 'CATEGORIZE_NEWS_ITEM_SUCCESS';
export const CATEGORIZE_NEWS_ITEM_FAILURE = 'CATEGORIZE_NEWS_ITEM_FAILURE';

export const SET_VIEW_STATE_FILTER = 'SET_NEWS_ADMIN_VIEW_STATE_FILTER';
export const SET_RANK_FILTER = 'SET_NEWS_ADMIN_RANK_FILTER';
export const SET_SORT_TERM = 'SET_NEWS_ADMIN_SORT_TERM';
export const SET_ORDER_OF_IMPORTANCE = 'SET_NEWS_ADMIN_ORDER_OF_IMPORTANCE'; // asc|desc
export const OPEN_RANKS_EDITOR = 'OPEN_NEWS_ADMIN_RANKS_EDITOR';
export const SET_NEWS_RANKS = 'SET_NEWS_ADMIN_STORY_RANKS';
export const ADD_RANK = 'NEWS_ADMIN_ADD_RANK';
