export default [
  'Default',
  'Greys',
  'YlGnBu',
  'Greens',
  'YlOrRd',
  'Bluered',
  'RdBu',
  'Reds',
  'Blues',
  'Picnic',
  'Rainbow',
  'Portland',
  'Jet',
  'Hot',
  'Blackbody',
  'Earth',
  'Electric',
  'Viridis',
  'Cividis',
];
